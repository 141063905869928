<template>
  <div>
    <div class="select-none space-y-1" :class="{ 'opacity-50': disabled }">
      <label
        v-if="!floatingLabel && (label || hasLabel)"
        :for="id || internalId"
      >
        <slot name="label">
          <span class="font-medium">{{ label }}</span>
        </slot>
      </label>
      <div class="relative">
        <span
          v-if="prependIcon || hasPrepend"
          class="absolute left-0 top-1/2 flex h-10 w-10 -translate-y-1/2 items-center justify-center"
        >
          <slot name="prepend">
            <UiIcon
              :name="prependIcon"
              class="pointer-events-none h-5 w-5 text-primary"
            />
          </slot>
        </span>

        <ClientOnly v-if="maskString || dataMaskaTokens">
          <input
            :id="id || internalId"
            v-maska
            :readonly="readonly"
            :name="name"
            :type="type"
            :disabled="disabled"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :data-maska="maskString"
            :data-maska-tokens="dataMaskaTokens"
            :class="
              cn(
                inputVariants({
                  size,
                  variant: inputVariant,
                  color: inputColor,
                }),
                inputPaddings,
                inputClasses,
              )
            "
            :data-e2e-placeholder="dataE2ePlaceholder"
            v-bind="inputBinds"
          />
        </ClientOnly>
        <input
          v-else
          :id="id || internalId"
          :readonly="readonly"
          :name="name"
          :type="type"
          :disabled="disabled"
          :placeholder="placeholder"
          :autocomplete="autocomplete"
          :class="
            cn(
              inputVariants({ size, variant: inputVariant, color: inputColor }),
              inputPaddings,
              inputClasses,
              'read-only:bg-off-white-10 read-only:focus:border-primary',
            )
          "
          :data-e2e-placeholder="dataE2ePlaceholder"
          v-bind="inputBinds"
        />
        <span
          v-if="appendIcon || hasAppend"
          class="absolute right-0 top-1/2 flex h-10 w-10 -translate-y-1/2 items-center justify-center"
        >
          <slot name="append">
            <UiIcon
              :name="appendIcon"
              class="h-5 w-5 text-gray-elements"
              :class="
                hasOnAppendClick ? 'cursor-pointer' : 'pointer-events-none'
              "
              @click="hasOnAppendClick ? onAppendClick($event) : null"
            />
          </slot>
        </span>
        <span
          v-if="isClearVisible"
          class="absolute right-0 top-1/2 flex h-10 w-10 -translate-y-1/2 items-center justify-center"
        >
          <button type="button" :aria-label="$t('clearInput')" @click="onClear">
            <UiIcon
              name="close"
              class="pointer-events-none z-30 h-5 w-5 font-bold text-primary"
            />
          </button>
        </span>
        <label
          v-if="floatingLabel && (label || hasLabel)"
          :for="id || internalId"
          class="peer-focus:-translate-y-1 peer-focus:scale-70 peer-disabled:pointer-events-none peer-disabled:opacity-50 peer-[:not(:placeholder-shown)]:-translate-y-1 peer-[:not(:placeholder-shown)]:scale-70"
          :class="
            cn(
              'pointer-events-none absolute top-0 h-full origin-[0_0] items-center truncate text-base leading-none text-neutral-black-200 transition duration-100 ease-in-out',
              [size === 'md' ? 'py-4' : 'py-3'],
              floatLabelHorizontal,
            )
          "
        >
          <slot name="label">
            <span>{{ label }}</span>
          </slot>
        </label>
      </div>
    </div>
    <SharedFieldHint
      v-if="!hideDetails && (errorMessage || hint)"
      :error="Boolean(errorMessage)"
      :message="errorMessage || hint"
    />
  </div>
</template>

<script setup lang="ts">
import { useField } from "vee-validate";
import type { HTMLAttributes } from "vue";
import { inputVariants, InputVariants } from "./variant";

const props = withDefaults(
  defineProps<{
    modelValue: any;
    name?: string;
    id?: string;
    type?: string;
    label?: string;
    hideDetails?: boolean;
    disabled?: boolean;
    prependIcon?: string;
    appendIcon?: string;
    placeholder?: string;
    hint?: string;
    readonly?: boolean;
    autocomplete?: string;
    lazy?: boolean;
    isClearVisible?: boolean;
    maskString?: string;
    dataMaskaTokens?: string;
    floatingLabel?: boolean;
    size?: InputVariants["size"];
    inputClasses?: HTMLAttributes["class"];
    focusOnAppendClick?: boolean;
    dataE2ePlaceholder?: string;
  }>(),
  {
    id: "",
    label: "",
    type: "text",
    prependIcon: "",
    appendIcon: "",
    placeholder: "",
    hint: "",
    autocomplete: "",
    name: "",
    lazy: true,
    isClearVisible: false,
    maskString: "",
    dataMaskaTokens: "",
  },
);

const instance = getCurrentInstance();
const inputEl = ref();
const emit = defineEmits([
  "update:modelValue",
  "click:append",
  "focus",
  "blur",
]);

const slots = useSlots();

const internalId = useId();

const name = toRef(props, "name");

const { errorMessage, handleChange, handleBlur, meta } = useField(
  name,
  undefined,
  {
    initialValue: props.modelValue,
  },
);

const hasLabel = computed(() => {
  return !!slots.label;
});

const hasPrepend = computed(() => {
  return !!slots.prepend;
});
const hasAppend = computed(() => {
  return !!slots.append;
});

const inputVariant = computed<InputVariants["variant"]>(() => {
  return props.floatingLabel ? "floating" : "default";
});

const inputColor = computed<InputVariants["color"]>(() => {
  return errorMessage.value ? "error" : "default";
});

const internalValue = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    if (props.name) {
      // handleChange(props.modelValue);
      handleChange(newValue);
    }
    emit("update:modelValue", newValue);
  },
});

const inputBinds = computed(() => {
  if (props.lazy) {
    return {
      value: props.modelValue,
      onChange: onInput,
      onBlur: myHandleBlur,
      onFocus: handleFocus,
    };
  } else {
    return {
      value: internalValue.value,
      onInput,
      onFocus: handleFocus,
      onBlur: emitBlurEvent,
    };
  }
});

const hasOnAppendClick = computed(() => {
  return Boolean(instance?.vnode?.props?.["onClick:append"]);
});

function onInput(e) {
  if (props.name) {
    handleChange(e);
  }
  emit("update:modelValue", e.target.value);
}

const inputPaddings = computed(() => {
  const classes: string[] = [];
  hasPrepend.value || props.prependIcon
    ? classes.push("pl-9")
    : classes.push("pl-2.5");

  hasAppend.value || props.appendIcon
    ? classes.push("pr-9")
    : classes.push("pr-2.5");

  return classes;
});

const floatLabelHorizontal = computed(() => {
  const classes: string[] = [];
  hasPrepend.value || props.prependIcon
    ? classes.push("left-9")
    : classes.push("left-2.5");

  hasAppend.value || props.appendIcon
    ? classes.push("right-9")
    : classes.push("right-2.5");

  return classes;
});

function onClear() {
  emit("update:modelValue", "");
}

function myHandleBlur(e: Event) {
  handleChange(e);
  handleBlur(e);
  emitBlurEvent(e);
}

function handleFocus(e) {
  emit("focus", e);
}

function emitBlurEvent(e) {
  emit("blur", e);
}

function onAppendClick(event: Event) {
  emit("click:append", event);
  if (props.focusOnAppendClick) {
    if (inputEl.value) {
      inputEl.value.focus();
    }
  }
}

defineExpose({
  meta,
  value: props.modelValue,
  name: props.name,
  errorMessage,
});
</script>
